<template>
	<Header
		:list-state="listState"
		search
		title="MemeVotes" />
	<Container>
		<ApiTable
			:list-state="listState"
			@row-click="openDetails">
			<Column
				field="vote_option"
				header="Vote Option" />
			<Column
				field="vote_date"
				header="Vote Date" />
			<Column
				:style="{ maxWidth: '72px', width: '72px' }"
				header="">
				<template #body="slotProps">
					<ApiTableRemoveButton :item="slotProps.data" />
				</template>
			</Column>
		</ApiTable>
	</Container>
</template>

<script setup lang="ts">
import Header from './components/Header.vue'
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Container from '@/components/Container.vue'
import type { MemeVote } from '@/models/MemeVote/Model'
import { useMemeVoteListState } from '@/models/MemeVote/States'
import ApiTableRemoveButton from '@/components/Table/ApiTableRemoveButton.vue'

const listState = useMemeVoteListState()
const router = useRouter()

onBeforeMount(() => {
	listState.getList()
})

function openDetails(item: { data: MemeVote }) {
	router.push({ name: 'meme-votes-edit', params: { id: item.data.id } })
}
</script>
