import type { MemeTemplateModel } from '@/models/MemeTemplate/Model'
import MemeTemplatesApi from '@/models/MemeTemplate/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeTemplateDetailsState extends DetailsState<MemeTemplatesApi, MemeTemplateModel> {
	api = new MemeTemplatesApi()
}

export function useMemeTemplateDetailsState() {
	return new MemeTemplateDetailsState()
}

export class MemeTemplateListState extends ListState<MemeTemplatesApi, MemeTemplateModel, LaravelPaginationResponse<MemeTemplateModel>> {
	api = new MemeTemplatesApi()
}

export function useMemeTemplateListState() {
	return new MemeTemplateListState()
}
