import type { CommentModel } from '@/models/Comment/Model'
import CommentsApi from '@/models/Comment/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class CommentDetailsState extends DetailsState<CommentsApi, CommentModel> {
	api = new CommentsApi()
}

export function useCommentDetailsState() {
	return new CommentDetailsState()
}

export class CommentListState extends ListState<CommentsApi, CommentModel, LaravelPaginationResponse<CommentModel>> {
	api = new CommentsApi()
}

export function useCommentListState() {
	return new CommentListState()
}
