import type {
	MemeTrendModel,
	MemeTrendStorePayload,
	MemeTrendUpdatePayload,
} from '@/models/MemeTrend/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeTrendsApi extends Api<
	MemeTrendModel,
	LaravelPaginationResponse<MemeTrendModel>,
	MemeTrendStorePayload,
	MemeTrendUpdatePayload
> {
	route = 'meme-trends'
}
