import type { MemeHistoryModel } from '@/models/MemeHistory/Model'
import MemeHistorysApi from '@/models/MemeHistory/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeHistoryDetailsState extends DetailsState<MemeHistorysApi, MemeHistoryModel> {
	api = new MemeHistorysApi()
}

export function useMemeHistoryDetailsState() {
	return new MemeHistoryDetailsState()
}

export class MemeHistoryListState extends ListState<MemeHistorysApi, MemeHistoryModel, LaravelPaginationResponse<MemeHistoryModel>> {
	api = new MemeHistorysApi()
}

export function useMemeHistoryListState() {
	return new MemeHistoryListState()
}
