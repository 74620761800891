import type {
	MemeVoteModel,
	MemeVoteStorePayload,
	MemeVoteUpdatePayload,
} from '@/models/MemeVote/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeVotesApi extends Api<
	MemeVoteModel,
	LaravelPaginationResponse<MemeVoteModel>,
	MemeVoteStorePayload,
	MemeVoteUpdatePayload
> {
	route = 'meme-votes'
}
