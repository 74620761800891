import type { MemeDiscussionModel } from '@/models/MemeDiscussion/Model'
import MemeDiscussionsApi from '@/models/MemeDiscussion/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeDiscussionDetailsState extends DetailsState<MemeDiscussionsApi, MemeDiscussionModel> {
	api = new MemeDiscussionsApi()
}

export function useMemeDiscussionDetailsState() {
	return new MemeDiscussionDetailsState()
}

export class MemeDiscussionListState extends ListState<MemeDiscussionsApi, MemeDiscussionModel, LaravelPaginationResponse<MemeDiscussionModel>> {
	api = new MemeDiscussionsApi()
}

export function useMemeDiscussionListState() {
	return new MemeDiscussionListState()
}
