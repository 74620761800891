import type { MemeInsightModel } from '@/models/MemeInsight/Model'
import MemeInsightsApi from '@/models/MemeInsight/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeInsightDetailsState extends DetailsState<MemeInsightsApi, MemeInsightModel> {
	api = new MemeInsightsApi()
}

export function useMemeInsightDetailsState() {
	return new MemeInsightDetailsState()
}

export class MemeInsightListState extends ListState<MemeInsightsApi, MemeInsightModel, LaravelPaginationResponse<MemeInsightModel>> {
	api = new MemeInsightsApi()
}

export function useMemeInsightListState() {
	return new MemeInsightListState()
}
