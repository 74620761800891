import type { MemeFormatModel } from '@/models/MemeFormat/Model'
import MemeFormatsApi from '@/models/MemeFormat/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeFormatDetailsState extends DetailsState<MemeFormatsApi, MemeFormatModel> {
	api = new MemeFormatsApi()
}

export function useMemeFormatDetailsState() {
	return new MemeFormatDetailsState()
}

export class MemeFormatListState extends ListState<MemeFormatsApi, MemeFormatModel, LaravelPaginationResponse<MemeFormatModel>> {
	api = new MemeFormatsApi()
}

export function useMemeFormatListState() {
	return new MemeFormatListState()
}
