import type { StoreModel, StoreStorePayload, StoreUpdatePayload } from '@/models/Store/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class StoresApi extends Api<
	StoreModel,
	LaravelPaginationResponse<StoreModel>,
	StoreStorePayload,
	StoreUpdatePayload
> {
	route = 'stores'
}
