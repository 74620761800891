import type {
	ReportModel,
	ReportStorePayload,
	ReportUpdatePayload,
} from '@/models/Report/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class ReportsApi extends Api<
	ReportModel,
	LaravelPaginationResponse<ReportModel>,
	ReportStorePayload,
	ReportUpdatePayload
> {
	route = 'reports'
}
