<template>
	<FormContainer
		class="form"
		:visible
		:title="props.isEdit ? 'Update MemeTrend' : 'Create MemeTrend'"
		:as-dialog
		@close="emit('close')">
		<form @submit.prevent="submit">
				<FormInput
					v-if="!props.hideInputs?.includes('trend_date')"
					:required="true"
					:error-message="formErrors.trend_date"
					label="Trend Date"
				>
					<DatePicker
						v-model="formData.trend_date"
						:disabled="!!props.forceValues.trend_date" />
				</FormInput>
				<FormInput
					v-if="!props.hideInputs?.includes('meme_id')"
					:required="false"
					:error-message="formErrors.meme_id"
					label="Meme"
				>
					<ModelSelect
						v-model="formData.meme_id"
						:api="new MemesApi()"
						:disabled="!!props.forceValues.meme_id"
						option-label="title" />
				</FormInput>
			<div class="form__footer-container">
				<Button
					v-if="props.isEdit && !props.hideRemove"
					severity="danger"
					icon="fal fa-trash"
					label="Remove"
					outlined
					:loading="loading"
					@click="remove" />
				<Button
					icon="fal fa-save"
					:loading="loading"
					:label="props.isEdit ? 'Update' : 'Create'"
					@click="submit"
					@submit.stop />
			</div>
		</form>
	</FormContainer>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import MemeTrendsApi from '@/models/MemeTrend/Api'
import type { MemeTrend } from '@/models/MemeTrend/Model'
import { useForm } from '@/helpers/form'
import FormInput from '@/components/FormInput.vue'
import Button from 'primevue/button'
import FormContainer from '@/components/FormContainer.vue'
import DatePicker from 'primevue/datepicker'
import InputText from 'primevue/inputtext'
import MemesApi from '@/models/Meme/Api'
import ModelSelect from '@/components/ModelSelect.vue'

type FormData = {
	trend_date: string,
	meme_id: number | undefined,
}

const emit = defineEmits([
	'start-loading',
	'stop-loading',
	'close',
	'created',
	'updated',
	'deleted',
])

const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: MemeTrend['id']
		hideInputs?: (keyof FormData)[]
		defaultValues?: Partial<FormData>
		forceValues?: Partial<FormData>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
		hideRemove?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
		hideRemove: false,
	},
)

const router = useRouter()
const { formData, loading, formErrors, reset, submit, remove, isDirty } = useForm({
	api: new MemeTrendsApi(),
	defaultData: () => ({
		trend_date: '',
		meme_id: undefined,
	}),
	forceValues: props.forceValues,
	attachTo: props.attachTo,
	isEdit: props.isEdit,
	id: props.id,
	onStartLoading: () => emit('start-loading'),
	onStopLoading: () => emit('stop-loading'),
	onClose: () => emit('close'),
	onCreated: (entity) => {
		if (props.shouldRedirect) {
			router.push({ name: 'meme-trends-edit', params: { id: entity!.id } })
		}
		emit('created', entity)
	},
	onUpdated: () => emit('updated'),
	onDeleted: () => emit('deleted'),
})

watch(
	() => props.visible,
	(val) => {
		if (!val) reset()
	},
)
</script>

<style lang="scss">
.form {
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__footer-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
		}

		&--edit {
			.form__footer-container {
				justify-content: space-between;
			}
		}
	}
}
</style>
