import type {
	MemeLicenseModel,
	MemeLicenseStorePayload,
	MemeLicenseUpdatePayload,
} from '@/models/MemeLicense/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeLicensesApi extends Api<
	MemeLicenseModel,
	LaravelPaginationResponse<MemeLicenseModel>,
	MemeLicenseStorePayload,
	MemeLicenseUpdatePayload
> {
	route = 'meme-licenses'
}
