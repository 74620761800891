import type {
	MemeHistoryModel,
	MemeHistoryStorePayload,
	MemeHistoryUpdatePayload,
} from '@/models/MemeHistory/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeHistorysApi extends Api<
	MemeHistoryModel,
	LaravelPaginationResponse<MemeHistoryModel>,
	MemeHistoryStorePayload,
	MemeHistoryUpdatePayload
> {
	route = 'meme-histories'
}
