import type {
	MemeCollectionModel,
	MemeCollectionStorePayload,
	MemeCollectionUpdatePayload,
} from '@/models/MemeCollection/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeCollectionsApi extends Api<
	MemeCollectionModel,
	LaravelPaginationResponse<MemeCollectionModel>,
	MemeCollectionStorePayload,
	MemeCollectionUpdatePayload
> {
	route = 'meme-collections'
}
