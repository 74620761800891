import type {
	MemeInsightModel,
	MemeInsightStorePayload,
	MemeInsightUpdatePayload,
} from '@/models/MemeInsight/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeInsightsApi extends Api<
	MemeInsightModel,
	LaravelPaginationResponse<MemeInsightModel>,
	MemeInsightStorePayload,
	MemeInsightUpdatePayload
> {
	route = 'meme-insights'
}
