import type { MemeLicenseModel } from '@/models/MemeLicense/Model'
import MemeLicensesApi from '@/models/MemeLicense/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeLicenseDetailsState extends DetailsState<MemeLicensesApi, MemeLicenseModel> {
	api = new MemeLicensesApi()
}

export function useMemeLicenseDetailsState() {
	return new MemeLicenseDetailsState()
}

export class MemeLicenseListState extends ListState<MemeLicensesApi, MemeLicenseModel, LaravelPaginationResponse<MemeLicenseModel>> {
	api = new MemeLicensesApi()
}

export function useMemeLicenseListState() {
	return new MemeLicenseListState()
}
