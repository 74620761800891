import type {
	MemeChallengeModel,
	MemeChallengeStorePayload,
	MemeChallengeUpdatePayload,
} from '@/models/MemeChallenge/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeChallengesApi extends Api<
	MemeChallengeModel,
	LaravelPaginationResponse<MemeChallengeModel>,
	MemeChallengeStorePayload,
	MemeChallengeUpdatePayload
> {
	route = 'meme-challenges'
}
