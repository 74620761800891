import type {
	MemeSubscriptionModel,
	MemeSubscriptionStorePayload,
	MemeSubscriptionUpdatePayload,
} from '@/models/MemeSubscription/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeSubscriptionsApi extends Api<
	MemeSubscriptionModel,
	LaravelPaginationResponse<MemeSubscriptionModel>,
	MemeSubscriptionStorePayload,
	MemeSubscriptionUpdatePayload
> {
	route = 'meme-subscriptions'
}
