import type {
	MemeContestModel,
	MemeContestStorePayload,
	MemeContestUpdatePayload,
} from '@/models/MemeContest/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeContestsApi extends Api<
	MemeContestModel,
	LaravelPaginationResponse<MemeContestModel>,
	MemeContestStorePayload,
	MemeContestUpdatePayload
> {
	route = 'meme-contests'
}
