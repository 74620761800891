<template>
	<Card class="comments-relation-widget">
		<template #title>
			<h4 class="comments-relation-widget__title">Comments</h4>
			<Button
				icon="fal fa-link"
				label="Connect"
				outlined
				severity="secondary"
				@click="isRelationAddDialogActive = true" />
			<Button
				icon="fal fa-plus"
				label="Create"
				outlined
				severity="secondary"
				@click="isFormActive = true" />
		</template>
		<template #content>
			<ApiTable
				flat
				:list-state="listState"
				@row-click="openDetails">
				<Column
					field="content"
					header="Content" />
				<Column header=""
					:style="{ maxWidth: '72px', width: '72px' }">
					<template #body="slotProps">
						<Button
							severity="secondary"
							outlined
							rounded
							icon="fal fa-times"
							:loading="dissociateLoading === slotProps.data.id"
							@click.stop.prevent="dissociate(slotProps.data)" />
					</template>
				</Column>
			</ApiTable>
		</template>
	</Card>
	<CommentsRelationAddDialog
		v-model="isRelationAddDialogActive"
		:meme-id="props.memeId"
		@update="refresh()" />
<CommentForm
	:as-dialog="true"
	:visible="isFormActive"
	:should-redirect="false"
	:force-values="{ meme_id: Number(props.memeId) }"
	:hide-inputs="['meme_id']"
	@close="isFormActive = false"
	@created="refresh()" />
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue'
import CommentsRelationAddDialog from './CommentsRelationAddDialog.vue'
import CommentForm from '@/views/Comment/components/Form.vue'
import Card from 'primevue/card'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import { useCommentListState } from '@/models/Comment/States'
import type { Comment } from '@/models/Comment/Model'
import type { Meme } from '@/models/Meme/Model'
import MemesApi from '@/models/Meme/Api'

const emit = defineEmits(['start-loading', 'stop-loading'])

const props = defineProps<{
	memeId: Meme['id']
}>()

const router = useRouter()
const isRelationAddDialogActive = ref(false)
const isFormActive = ref(false)
const dissociateLoading = ref(null as null | number | string)
const listLoading = ref(false)
const listState = useCommentListState()
listState.defaultParams = {
	per_page: 10,
	fromRelation: {
		model: 'App\\Models\\Meme',
		id: props.memeId,
		relation: 'comments',
	},
}

onBeforeMount(() => {
	refresh()
})

async function refresh() {
	listLoading.value = true
	emit('start-loading')
	await listState.getList()
	listLoading.value = false
	emit('stop-loading')
}

async function dissociate(item: Comment) {
	dissociateLoading.value = item.id
	emit('start-loading')
	await new MemesApi().updateRelation(props.memeId, 'comments', {
		method: 'dissociate',
		params: [item.id],
	})
	dissociateLoading.value = null
	await refresh()
	emit('stop-loading')
}

function openDetails(item: { data: Comment }) {
	router.push({ name: 'comments-edit', params: { id: item.data.id } })
}
</script>

<style lang="scss" scoped>
.comments-relation-widget {
	width: 100%;
	max-width: 600px;
	overflow: hidden;

	:deep(.p-card-body) {
		padding: 20px 0 0;

		.p-card-caption {
			padding: 0px 20px 12px;

			.p-card-title {
				display: flex;
				align-items: center;
				gap: 10px;

				.comments-relation-widget__title {
					flex: 1;
					text-align: left;
				}
			}
		}
	}
}
</style>
