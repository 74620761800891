<template>
	<Card class="memes-relation-widget">
		<template #title>
			<h4 class="memes-relation-widget__title">Memes</h4>
			<Button
				icon="fal fa-link"
				label="Connect"
				outlined
				severity="secondary"
				@click="isRelationAddDialogActive = true" />
			<Button
				icon="fal fa-plus"
				label="Create"
				outlined
				severity="secondary"
				@click="isFormActive = true" />
		</template>
		<template #content>
			<ApiTable
				flat
				:list-state="listState"
				@row-click="openDetails">
				<Column
					field="title"
					header="Title" />
				<Column header=""
					:style="{ maxWidth: '72px', width: '72px' }">
					<template #body="slotProps">
						<Button
							severity="secondary"
							outlined
							rounded
							icon="fal fa-times"
							:loading="detachLoading === slotProps.data.id"
							@click.stop.prevent="detach(slotProps.data)" />
					</template>
				</Column>
			</ApiTable>
		</template>
	</Card>
	<MemesRelationAddDialog
		v-model="isRelationAddDialogActive"
		:meme-collection-id="props.memeCollectionId"
		@update="refresh()" />
<MemeForm
	:as-dialog="true"
	:visible="isFormActive"
	:should-redirect="false"
	:attach-to="{ collections: { method: 'syncWithoutDetaching', id: props.memeCollectionId } }"
	@close="isFormActive = false"
	@created="refresh()" />
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue'
import MemesRelationAddDialog from './MemesRelationAddDialog.vue'
import MemeForm from '@/views/Meme/components/Form.vue'
import Card from 'primevue/card'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import { useMemeListState } from '@/models/Meme/States'
import type { Meme } from '@/models/Meme/Model'
import type { MemeCollection } from '@/models/MemeCollection/Model'
import MemeCollectionsApi from '@/models/MemeCollection/Api'

const emit = defineEmits(['start-loading', 'stop-loading'])

const props = defineProps<{
	memeCollectionId: MemeCollection['id']
}>()

const router = useRouter()
const isRelationAddDialogActive = ref(false)
const isFormActive = ref(false)
const detachLoading = ref(null as null | number | string)
const listLoading = ref(false)
const listState = useMemeListState()
listState.defaultParams = {
	per_page: 10,
	fromRelation: {
		model: 'App\\Models\\MemeCollection',
		id: props.memeCollectionId,
		relation: 'memes',
	},
}

onBeforeMount(() => {
	refresh()
})

async function refresh() {
	listLoading.value = true
	emit('start-loading')
	await listState.getList()
	listLoading.value = false
	emit('stop-loading')
}

async function detach(item: Meme) {
	detachLoading.value = item.id
	emit('start-loading')
	await new MemeCollectionsApi().updateRelation(props.memeCollectionId, 'memes', {
		method: 'detach',
		params: [item.id],
	})
	detachLoading.value = null
	await refresh()
	emit('stop-loading')
}

function openDetails(item: { data: Meme }) {
	router.push({ name: 'memes-edit', params: { id: item.data.id } })
}
</script>

<style lang="scss" scoped>
.memes-relation-widget {
	width: 100%;
	max-width: 600px;
	overflow: hidden;

	:deep(.p-card-body) {
		padding: 20px 0 0;

		.p-card-caption {
			padding: 0px 20px 12px;

			.p-card-title {
				display: flex;
				align-items: center;
				gap: 10px;

				.memes-relation-widget__title {
					flex: 1;
					text-align: left;
				}
			}
		}
	}
}
</style>
