<template>
	<div
		class="navigation"
		:class="{ 'navigation--expanded': locked }"
		@mouseover="isSidebarOpen = true"
		@mouseleave="isSidebarOpen = false">
		<div class="navigation__header">
			<img
				class="navigation__logo"
				src="@/assets/logo.svg"
				alt="logo" />
			<Button
				v-tooltip.bottom="locked ? 'Unlock sidebar' : 'Lock sidebar'"
				severity="secondary"
				:icon="lockIcon"
				text
				@click="toggleLocked" />
		</div>
		<div class="navigation__links-container">
			<router-link
				class="navigation__link-item"
				to="/memes">
				<i class="navigation__link-icon far fa-light fa-image"></i>
				<div class="navigation__link-title">Memes</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/tags">
				<i class="navigation__link-icon far fa-light fa-tags"></i>
				<div class="navigation__link-title">Tags</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/categories">
				<i class="navigation__link-icon far fa-light fa-tags"></i>
				<div class="navigation__link-title">Categories</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/comments">
				<i class="navigation__link-icon far fa-light fa-comments"></i>
				<div class="navigation__link-title">Comments</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/reactions">
				<i class="navigation__link-icon far fa-light fa-smile"></i>
				<div class="navigation__link-title">Reactions</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/favorites">
				<i class="navigation__link-icon far fa-light fa-heart"></i>
				<div class="navigation__link-title">Favorites</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/reports">
				<i class="navigation__link-icon far fa-light fa-file-alt"></i>
				<div class="navigation__link-title">Reports</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-templates">
				<i class="navigation__link-icon far fa-light fa-image"></i>
				<div class="navigation__link-title">Meme Templates</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-formats">
				<i class="navigation__link-icon far fa-light fa-image"></i>
				<div class="navigation__link-title">Meme Formats</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-events">
				<i class="navigation__link-icon far fa-light fa-calendar-alt"></i>
				<div class="navigation__link-title">Meme Events</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-contests">
				<i class="navigation__link-icon far fa-light fa-trophy"></i>
				<div class="navigation__link-title">Meme Contests</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-awards">
				<i class="navigation__link-icon far fa-light fa-trophy"></i>
				<div class="navigation__link-title">Meme Awards</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-collections">
				<i class="navigation__link-icon far fa-light fa-laugh-squint"></i>
				<div class="navigation__link-title">Meme Collections</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-challenges">
				<i class="navigation__link-icon far fa-light fa-trophy"></i>
				<div class="navigation__link-title">Meme Challenges</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-trends">
				<i class="navigation__link-icon far fa-light fa-chart-line"></i>
				<div class="navigation__link-title">Meme Trends</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-histories">
				<i class="navigation__link-icon far fa-light fa-history"></i>
				<div class="navigation__link-title">Meme Histories</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-licenses">
				<i class="navigation__link-icon far fa-light fa-file-contract"></i>
				<div class="navigation__link-title">Meme Licenses</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-statistics">
				<i class="navigation__link-icon far fa-light fa-chart-bar"></i>
				<div class="navigation__link-title">Meme Statistics</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-feedbacks">
				<i class="navigation__link-icon far fa-light fa-comments"></i>
				<div class="navigation__link-title">Meme Feedbacks</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-subscriptions">
				<i class="navigation__link-icon far fa-light fa-calendar-check"></i>
				<div class="navigation__link-title">Meme Subscriptions</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-polls">
				<i class="navigation__link-icon far fa-light fa-poll"></i>
				<div class="navigation__link-title">Meme Polls</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-votes">
				<i class="navigation__link-icon far fa-light fa-vote-yea"></i>
				<div class="navigation__link-title">Meme Votes</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-discussions">
				<i class="navigation__link-icon far fa-light fa-comments"></i>
				<div class="navigation__link-title">Meme Discussions</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/meme-insights">
				<i class="navigation__link-icon far fa-light fa-chart-line"></i>
				<div class="navigation__link-title">Meme Insights</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/users">
				<i class="navigation__link-icon far far fa-user"></i>
				<div class="navigation__link-title">Users</div>
			</router-link>
		</div>
		<div class="navigation__links-container navigation__links-container--bottom">
			<router-link
				class="navigation__link-item"
				to="/clear-data">
				<i class="navigation__link-icon far fa-database"></i>
				<div class="navigation__link-title">Clear Data</div>
			</router-link>
		</div>
		<div class="navigation__footer">
			<Avatar
				:label="initials"
				class="navigation__avatar" />
			<span
				v-tooltip.top="auth.user!.email"
				class="navigation__email"
				>{{ auth.user!.email }}</span
			>
			<Button
				v-tooltip.top="'Logout'"
				as="router-link"
				to="/logout"
				severity="secondary"
				icon="fas fa-arrow-right-from-bracket"
				text
				@click="toggleLocked" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/Auth'
import Button from 'primevue/button'
import Avatar from 'primevue/avatar'

const auth = useAuthStore()
const locked = ref(localStorage.getItem('sidebarLocked') === 'true')
const isSidebarOpen = ref(false)

const initials = computed(
	() => auth.user!.email[0].toUpperCase() + auth.user!.email[1].toUpperCase(),
)
const lockIcon = computed(() => {
	if (locked.value) {
		return 'fal fa-lock'
	} else {
		return 'fal fa-lock-open'
	}
})

function toggleLocked() {
	locked.value = !locked.value
	localStorage.setItem('sidebarLocked', locked.value.toString())
}
</script>

<style scoped lang="scss">
.navigation {
	border-right: 1px solid var(--p-surface-200);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 52px;
	transition: width 0.3s;
	background-color: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		border-right-color: var(--p-surface-900);
		background-color: var(--p-surface-950);
	}

	&.navigation--expanded,
	&:hover {
		width: 255px;

		.navigation__header {
			.navigation__logo {
				max-width: 88.83px;
			}
		}
	}

	.navigation__header {
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 10px;
		border-bottom: 1px solid var(--p-surface-200);
		width: 255px;
		height: 64px;
		text-overflow: ellipsis;
		justify-content: space-between;

		@media (prefers-color-scheme: dark) {
			border-bottom-color: var(--p-surface-950);
		}

		.navigation__logo {
			max-width: 32px;
			transition: max-width 0.3s;
			flex-basis: content;
			max-height: 100%;
		}
	}

	.navigation__footer {
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 10px;
		border-top: 1px solid var(--p-surface-200);
		width: 255px;
		height: 64px;
		text-overflow: ellipsis;

		@media (prefers-color-scheme: dark) {
			border-top-color: var(--p-surface-950);
		}

		.navigation__email {
			flex: 1;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.navigation__avatar {
			min-width: 30px;
		}
	}

	.navigation__links-container {
		width: 255px;
		flex: 1;

		&--bottom {
			flex: 0;
		}

		.navigation__link-item {
			display: flex;
			align-items: center;
			padding: 10px;
			gap: 10px;
			color: var(--p-text-color);
			text-decoration: none;

			&:hover {
				background-color: var(--p-surface-100);

				@media (prefers-color-scheme: dark) {
					background-color: var(--p-surface-900);
				}
			}

			&.router-link-active {
				background-color: var(--p-surface-100);

				@media (prefers-color-scheme: dark) {
					background-color: var(--p-surface-900);
				}
			}

			.navigation__link-title {
				flex: 1;
			}

			.navigation__link-icon {
				font-size: 22px;
				color: var(--p-surface-500);
				width: 32px;
				display: flex;
				justify-content: center;

				@media (prefers-color-scheme: dark) {
					color: var(--p-surface-400);
				}
			}
		}
	}

	.navigation__logo-container {
		padding: 8px;
	}
}
</style>
