import type {
	MemeModel,
	MemeStorePayload,
	MemeUpdatePayload,
} from '@/models/Meme/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemesApi extends Api<
	MemeModel,
	LaravelPaginationResponse<MemeModel>,
	MemeStorePayload,
	MemeUpdatePayload
> {
	route = 'memes'
}
