import type {
	MemePollModel,
	MemePollStorePayload,
	MemePollUpdatePayload,
} from '@/models/MemePoll/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemePollsApi extends Api<
	MemePollModel,
	LaravelPaginationResponse<MemePollModel>,
	MemePollStorePayload,
	MemePollUpdatePayload
> {
	route = 'meme-polls'
}
