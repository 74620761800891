import type { PublisherModel } from '@/models/Publisher/Model'
import PublishersApi from '@/models/Publisher/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class PublisherDetailsState extends DetailsState<PublishersApi, PublisherModel> {
	api = new PublishersApi()
}

export function usePublisherDetailsState() {
	return new PublisherDetailsState()
}

export class PublisherListState extends ListState<PublishersApi, PublisherModel, LaravelPaginationResponse<PublisherModel>> {
	api = new PublishersApi()
}

export function usePublisherListState() {
	return new PublisherListState()
}
