import { createRouter, createWebHistory } from 'vue-router'
import authGuard from './guards/authGuard'
import { useAuthStore } from '@/stores/Auth'
import NotFound from '../views/NotFound.vue'

import Login from '../views/Auth/Login.vue'
import AdminApi from '@/helpers/api/AdminApi'
import MemeList from '../views/Meme/List.vue'
import MemeEdit from '../views/Meme/Edit.vue'
import TagList from '../views/Tag/List.vue'
import TagEdit from '../views/Tag/Edit.vue'
import CategoryList from '../views/Category/List.vue'
import CategoryEdit from '../views/Category/Edit.vue'
import CommentList from '../views/Comment/List.vue'
import CommentEdit from '../views/Comment/Edit.vue'
import ReactionList from '../views/Reaction/List.vue'
import ReactionEdit from '../views/Reaction/Edit.vue'
import FavoriteList from '../views/Favorite/List.vue'
import FavoriteEdit from '../views/Favorite/Edit.vue'
import ReportList from '../views/Report/List.vue'
import ReportEdit from '../views/Report/Edit.vue'
import MemeTemplateList from '../views/MemeTemplate/List.vue'
import MemeTemplateEdit from '../views/MemeTemplate/Edit.vue'
import MemeFormatList from '../views/MemeFormat/List.vue'
import MemeFormatEdit from '../views/MemeFormat/Edit.vue'
import MemeEventList from '../views/MemeEvent/List.vue'
import MemeEventEdit from '../views/MemeEvent/Edit.vue'
import MemeContestList from '../views/MemeContest/List.vue'
import MemeContestEdit from '../views/MemeContest/Edit.vue'
import MemeAwardList from '../views/MemeAward/List.vue'
import MemeAwardEdit from '../views/MemeAward/Edit.vue'
import MemeCollectionList from '../views/MemeCollection/List.vue'
import MemeCollectionEdit from '../views/MemeCollection/Edit.vue'
import MemeChallengeList from '../views/MemeChallenge/List.vue'
import MemeChallengeEdit from '../views/MemeChallenge/Edit.vue'
import MemeTrendList from '../views/MemeTrend/List.vue'
import MemeTrendEdit from '../views/MemeTrend/Edit.vue'
import MemeHistoryList from '../views/MemeHistory/List.vue'
import MemeHistoryEdit from '../views/MemeHistory/Edit.vue'
import MemeLicenseList from '../views/MemeLicense/List.vue'
import MemeLicenseEdit from '../views/MemeLicense/Edit.vue'
import MemeStatisticList from '../views/MemeStatistic/List.vue'
import MemeStatisticEdit from '../views/MemeStatistic/Edit.vue'
import MemeFeedbackList from '../views/MemeFeedback/List.vue'
import MemeFeedbackEdit from '../views/MemeFeedback/Edit.vue'
import MemeSubscriptionList from '../views/MemeSubscription/List.vue'
import MemeSubscriptionEdit from '../views/MemeSubscription/Edit.vue'
import MemePollList from '../views/MemePoll/List.vue'
import MemePollEdit from '../views/MemePoll/Edit.vue'
import MemeVoteList from '../views/MemeVote/List.vue'
import MemeVoteEdit from '../views/MemeVote/Edit.vue'
import MemeDiscussionList from '../views/MemeDiscussion/List.vue'
import MemeDiscussionEdit from '../views/MemeDiscussion/Edit.vue'
import MemeInsightList from '../views/MemeInsight/List.vue'
import MemeInsightEdit from '../views/MemeInsight/Edit.vue'
import UserList from '../views/User/List.vue'
import UserEdit from '../views/User/Edit.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			redirect: '/memes',
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			beforeEnter: () => {
				const auth = useAuthStore()
				if (auth.user) {
					return { path: auth.lastRoute ?? '/posts' }
				}
			},
		},
		{
			path: '/logout',
			component: Login,
			beforeEnter: async () => {
				const auth = useAuthStore()
				await auth.logout()
				return { path: '/login' }
			},
		},
		{
			path: '/clear-data',
			component: Login,
			beforeEnter: async () => {
				await AdminApi.clearData()
				window.location.reload()
				throw new Error('Clear data')
			},
		},
		{
			path: '/memes',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'memes-list',
					component: MemeList,
				},
				{
					path: '/memes/create',
					name: 'memes-create',
					component: MemeEdit,
				},
				{
					path: '/memes/:id',
					name: 'memes-edit',
					component: MemeEdit,
				},
			],
		},
		{
			path: '/tags',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'tags-list',
					component: TagList,
				},
				{
					path: '/tags/create',
					name: 'tags-create',
					component: TagEdit,
				},
				{
					path: '/tags/:id',
					name: 'tags-edit',
					component: TagEdit,
				},
			],
		},
		{
			path: '/categories',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'categories-list',
					component: CategoryList,
				},
				{
					path: '/categories/create',
					name: 'categories-create',
					component: CategoryEdit,
				},
				{
					path: '/categories/:id',
					name: 'categories-edit',
					component: CategoryEdit,
				},
			],
		},
		{
			path: '/comments',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'comments-list',
					component: CommentList,
				},
				{
					path: '/comments/create',
					name: 'comments-create',
					component: CommentEdit,
				},
				{
					path: '/comments/:id',
					name: 'comments-edit',
					component: CommentEdit,
				},
			],
		},
		{
			path: '/reactions',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'reactions-list',
					component: ReactionList,
				},
				{
					path: '/reactions/create',
					name: 'reactions-create',
					component: ReactionEdit,
				},
				{
					path: '/reactions/:id',
					name: 'reactions-edit',
					component: ReactionEdit,
				},
			],
		},
		{
			path: '/favorites',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'favorites-list',
					component: FavoriteList,
				},
				{
					path: '/favorites/create',
					name: 'favorites-create',
					component: FavoriteEdit,
				},
				{
					path: '/favorites/:id',
					name: 'favorites-edit',
					component: FavoriteEdit,
				},
			],
		},
		{
			path: '/reports',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'reports-list',
					component: ReportList,
				},
				{
					path: '/reports/create',
					name: 'reports-create',
					component: ReportEdit,
				},
				{
					path: '/reports/:id',
					name: 'reports-edit',
					component: ReportEdit,
				},
			],
		},
		{
			path: '/meme-templates',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-templates-list',
					component: MemeTemplateList,
				},
				{
					path: '/meme-templates/create',
					name: 'meme-templates-create',
					component: MemeTemplateEdit,
				},
				{
					path: '/meme-templates/:id',
					name: 'meme-templates-edit',
					component: MemeTemplateEdit,
				},
			],
		},
		{
			path: '/meme-formats',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-formats-list',
					component: MemeFormatList,
				},
				{
					path: '/meme-formats/create',
					name: 'meme-formats-create',
					component: MemeFormatEdit,
				},
				{
					path: '/meme-formats/:id',
					name: 'meme-formats-edit',
					component: MemeFormatEdit,
				},
			],
		},
		{
			path: '/meme-events',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-events-list',
					component: MemeEventList,
				},
				{
					path: '/meme-events/create',
					name: 'meme-events-create',
					component: MemeEventEdit,
				},
				{
					path: '/meme-events/:id',
					name: 'meme-events-edit',
					component: MemeEventEdit,
				},
			],
		},
		{
			path: '/meme-contests',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-contests-list',
					component: MemeContestList,
				},
				{
					path: '/meme-contests/create',
					name: 'meme-contests-create',
					component: MemeContestEdit,
				},
				{
					path: '/meme-contests/:id',
					name: 'meme-contests-edit',
					component: MemeContestEdit,
				},
			],
		},
		{
			path: '/meme-awards',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-awards-list',
					component: MemeAwardList,
				},
				{
					path: '/meme-awards/create',
					name: 'meme-awards-create',
					component: MemeAwardEdit,
				},
				{
					path: '/meme-awards/:id',
					name: 'meme-awards-edit',
					component: MemeAwardEdit,
				},
			],
		},
		{
			path: '/meme-collections',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-collections-list',
					component: MemeCollectionList,
				},
				{
					path: '/meme-collections/create',
					name: 'meme-collections-create',
					component: MemeCollectionEdit,
				},
				{
					path: '/meme-collections/:id',
					name: 'meme-collections-edit',
					component: MemeCollectionEdit,
				},
			],
		},
		{
			path: '/meme-challenges',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-challenges-list',
					component: MemeChallengeList,
				},
				{
					path: '/meme-challenges/create',
					name: 'meme-challenges-create',
					component: MemeChallengeEdit,
				},
				{
					path: '/meme-challenges/:id',
					name: 'meme-challenges-edit',
					component: MemeChallengeEdit,
				},
			],
		},
		{
			path: '/meme-trends',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-trends-list',
					component: MemeTrendList,
				},
				{
					path: '/meme-trends/create',
					name: 'meme-trends-create',
					component: MemeTrendEdit,
				},
				{
					path: '/meme-trends/:id',
					name: 'meme-trends-edit',
					component: MemeTrendEdit,
				},
			],
		},
		{
			path: '/meme-histories',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-histories-list',
					component: MemeHistoryList,
				},
				{
					path: '/meme-histories/create',
					name: 'meme-histories-create',
					component: MemeHistoryEdit,
				},
				{
					path: '/meme-histories/:id',
					name: 'meme-histories-edit',
					component: MemeHistoryEdit,
				},
			],
		},
		{
			path: '/meme-licenses',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-licenses-list',
					component: MemeLicenseList,
				},
				{
					path: '/meme-licenses/create',
					name: 'meme-licenses-create',
					component: MemeLicenseEdit,
				},
				{
					path: '/meme-licenses/:id',
					name: 'meme-licenses-edit',
					component: MemeLicenseEdit,
				},
			],
		},
		{
			path: '/meme-statistics',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-statistics-list',
					component: MemeStatisticList,
				},
				{
					path: '/meme-statistics/create',
					name: 'meme-statistics-create',
					component: MemeStatisticEdit,
				},
				{
					path: '/meme-statistics/:id',
					name: 'meme-statistics-edit',
					component: MemeStatisticEdit,
				},
			],
		},
		{
			path: '/meme-feedbacks',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-feedbacks-list',
					component: MemeFeedbackList,
				},
				{
					path: '/meme-feedbacks/create',
					name: 'meme-feedbacks-create',
					component: MemeFeedbackEdit,
				},
				{
					path: '/meme-feedbacks/:id',
					name: 'meme-feedbacks-edit',
					component: MemeFeedbackEdit,
				},
			],
		},
		{
			path: '/meme-subscriptions',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-subscriptions-list',
					component: MemeSubscriptionList,
				},
				{
					path: '/meme-subscriptions/create',
					name: 'meme-subscriptions-create',
					component: MemeSubscriptionEdit,
				},
				{
					path: '/meme-subscriptions/:id',
					name: 'meme-subscriptions-edit',
					component: MemeSubscriptionEdit,
				},
			],
		},
		{
			path: '/meme-polls',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-polls-list',
					component: MemePollList,
				},
				{
					path: '/meme-polls/create',
					name: 'meme-polls-create',
					component: MemePollEdit,
				},
				{
					path: '/meme-polls/:id',
					name: 'meme-polls-edit',
					component: MemePollEdit,
				},
			],
		},
		{
			path: '/meme-votes',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-votes-list',
					component: MemeVoteList,
				},
				{
					path: '/meme-votes/create',
					name: 'meme-votes-create',
					component: MemeVoteEdit,
				},
				{
					path: '/meme-votes/:id',
					name: 'meme-votes-edit',
					component: MemeVoteEdit,
				},
			],
		},
		{
			path: '/meme-discussions',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-discussions-list',
					component: MemeDiscussionList,
				},
				{
					path: '/meme-discussions/create',
					name: 'meme-discussions-create',
					component: MemeDiscussionEdit,
				},
				{
					path: '/meme-discussions/:id',
					name: 'meme-discussions-edit',
					component: MemeDiscussionEdit,
				},
			],
		},
		{
			path: '/meme-insights',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'meme-insights-list',
					component: MemeInsightList,
				},
				{
					path: '/meme-insights/create',
					name: 'meme-insights-create',
					component: MemeInsightEdit,
				},
				{
					path: '/meme-insights/:id',
					name: 'meme-insights-edit',
					component: MemeInsightEdit,
				},
			],
		},
		{
			path: '/users',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'users-list',
					component: UserList,
				},
				{
					path: '/users/create',
					name: 'users-create',
					component: UserEdit,
				},
				{
					path: '/users/:id',
					name: 'users-edit',
					component: UserEdit,
				},
			],
		},
		{
			path: '/404-not-found',
			name: 'not-found',
			component: NotFound,
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/login',
		},
	],
})

export default router
