import type { AuthorModel, AuthorStorePayload, AuthorUpdatePayload } from '@/models/Author/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class AuthorsApi extends Api<
	AuthorModel,
	LaravelPaginationResponse<AuthorModel>,
	AuthorStorePayload,
	AuthorUpdatePayload
> {
	route = 'authors'
}
