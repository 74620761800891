import type {
	MemeAwardModel,
	MemeAwardStorePayload,
	MemeAwardUpdatePayload,
} from '@/models/MemeAward/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeAwardsApi extends Api<
	MemeAwardModel,
	LaravelPaginationResponse<MemeAwardModel>,
	MemeAwardStorePayload,
	MemeAwardUpdatePayload
> {
	route = 'meme-awards'
}
