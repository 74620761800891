<template>
	<Card class="books-relation-widget">
		<template #title>
			<h4 class="books-relation-widget__title">Books</h4>
			<Button
				icon="fal fa-link"
				label="Connect"
				outlined
				severity="secondary"
				@click="isRelationAddDialogActive = true" />
			<Button
				icon="fal fa-plus"
				label="Create"
				outlined
				severity="secondary"
				@click="isFormActive = true" />
		</template>
		<template #content>
			<ApiTable
				flat
				:list-state="listState"
				@row-click="openDetails">
				<Column
					field="title"
					header="Title" />
				<Column header=""
					:style="{ maxWidth: '72px', width: '72px' }">
					<template #body="slotProps">
						<Button
							severity="secondary"
							outlined
							rounded
							icon="fal fa-times"
							:loading="detachLoading === slotProps.data.id"
							@click.stop.prevent="detach(slotProps.data)" />
					</template>
				</Column>
			</ApiTable>
		</template>
	</Card>
	<BooksRelationAddDialog
		v-model="isRelationAddDialogActive"
		:category-id="props.categoryId"
		@update="refresh()" />
<BookForm
	:as-dialog="true"
	:visible="isFormActive"
	:should-redirect="false"
	:attach-to="{ categories: { method: 'syncWithoutDetaching', id: props.categoryId } }"
	@close="isFormActive = false"
	@created="refresh()" />
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue'
import BooksRelationAddDialog from './BooksRelationAddDialog.vue'
import BookForm from '@/views/Book/components/Form.vue'
import Card from 'primevue/card'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import { useBookListState } from '@/models/Book/States'
import type { Book } from '@/models/Book/Model'
import type { Category } from '@/models/Category/Model'
import CategorysApi from '@/models/Category/Api'

const emit = defineEmits(['start-loading', 'stop-loading'])

const props = defineProps<{
	categoryId: Category['id']
}>()

const router = useRouter()
const isRelationAddDialogActive = ref(false)
const isFormActive = ref(false)
const detachLoading = ref(null as null | number | string)
const listLoading = ref(false)
const listState = useBookListState()
listState.defaultParams = {
	per_page: 10,
	fromRelation: {
		model: 'App\\Models\\Category',
		id: props.categoryId,
		relation: 'books',
	},
}

onBeforeMount(() => {
	refresh()
})

async function refresh() {
	listLoading.value = true
	emit('start-loading')
	await listState.getList()
	listLoading.value = false
	emit('stop-loading')
}

async function detach(item: Book) {
	detachLoading.value = item.id
	emit('start-loading')
	await new CategorysApi().updateRelation(props.categoryId, 'books', {
		method: 'detach',
		params: [item.id],
	})
	detachLoading.value = null
	await refresh()
	emit('stop-loading')
}

function openDetails(item: { data: Book }) {
	router.push({ name: 'books-edit', params: { id: item.data.id } })
}
</script>

<style lang="scss" scoped>
.books-relation-widget {
	width: 100%;
	max-width: 600px;
	overflow: hidden;

	:deep(.p-card-body) {
		padding: 20px 0 0;

		.p-card-caption {
			padding: 0px 20px 12px;

			.p-card-title {
				display: flex;
				align-items: center;
				gap: 10px;

				.books-relation-widget__title {
					flex: 1;
					text-align: left;
				}
			}
		}
	}
}
</style>
