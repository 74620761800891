import type { MemeModel } from '@/models/Meme/Model'
import MemesApi from '@/models/Meme/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeDetailsState extends DetailsState<MemesApi, MemeModel> {
	api = new MemesApi()
}

export function useMemeDetailsState() {
	return new MemeDetailsState()
}

export class MemeListState extends ListState<MemesApi, MemeModel, LaravelPaginationResponse<MemeModel>> {
	api = new MemesApi()
}

export function useMemeListState() {
	return new MemeListState()
}
