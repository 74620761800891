import type {
	ReactionModel,
	ReactionStorePayload,
	ReactionUpdatePayload,
} from '@/models/Reaction/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class ReactionsApi extends Api<
	ReactionModel,
	LaravelPaginationResponse<ReactionModel>,
	ReactionStorePayload,
	ReactionUpdatePayload
> {
	route = 'reactions'
}
