import type { MemeContestModel } from '@/models/MemeContest/Model'
import MemeContestsApi from '@/models/MemeContest/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeContestDetailsState extends DetailsState<MemeContestsApi, MemeContestModel> {
	api = new MemeContestsApi()
}

export function useMemeContestDetailsState() {
	return new MemeContestDetailsState()
}

export class MemeContestListState extends ListState<MemeContestsApi, MemeContestModel, LaravelPaginationResponse<MemeContestModel>> {
	api = new MemeContestsApi()
}

export function useMemeContestListState() {
	return new MemeContestListState()
}
