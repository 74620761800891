import type { MemeVoteModel } from '@/models/MemeVote/Model'
import MemeVotesApi from '@/models/MemeVote/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeVoteDetailsState extends DetailsState<MemeVotesApi, MemeVoteModel> {
	api = new MemeVotesApi()
}

export function useMemeVoteDetailsState() {
	return new MemeVoteDetailsState()
}

export class MemeVoteListState extends ListState<MemeVotesApi, MemeVoteModel, LaravelPaginationResponse<MemeVoteModel>> {
	api = new MemeVotesApi()
}

export function useMemeVoteListState() {
	return new MemeVoteListState()
}
