import type { MemeAwardModel } from '@/models/MemeAward/Model'
import MemeAwardsApi from '@/models/MemeAward/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeAwardDetailsState extends DetailsState<MemeAwardsApi, MemeAwardModel> {
	api = new MemeAwardsApi()
}

export function useMemeAwardDetailsState() {
	return new MemeAwardDetailsState()
}

export class MemeAwardListState extends ListState<MemeAwardsApi, MemeAwardModel, LaravelPaginationResponse<MemeAwardModel>> {
	api = new MemeAwardsApi()
}

export function useMemeAwardListState() {
	return new MemeAwardListState()
}
