import type { MemeChallengeModel } from '@/models/MemeChallenge/Model'
import MemeChallengesApi from '@/models/MemeChallenge/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeChallengeDetailsState extends DetailsState<MemeChallengesApi, MemeChallengeModel> {
	api = new MemeChallengesApi()
}

export function useMemeChallengeDetailsState() {
	return new MemeChallengeDetailsState()
}

export class MemeChallengeListState extends ListState<MemeChallengesApi, MemeChallengeModel, LaravelPaginationResponse<MemeChallengeModel>> {
	api = new MemeChallengesApi()
}

export function useMemeChallengeListState() {
	return new MemeChallengeListState()
}
