import type { MemeFeedbackModel } from '@/models/MemeFeedback/Model'
import MemeFeedbacksApi from '@/models/MemeFeedback/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeFeedbackDetailsState extends DetailsState<MemeFeedbacksApi, MemeFeedbackModel> {
	api = new MemeFeedbacksApi()
}

export function useMemeFeedbackDetailsState() {
	return new MemeFeedbackDetailsState()
}

export class MemeFeedbackListState extends ListState<MemeFeedbacksApi, MemeFeedbackModel, LaravelPaginationResponse<MemeFeedbackModel>> {
	api = new MemeFeedbacksApi()
}

export function useMemeFeedbackListState() {
	return new MemeFeedbackListState()
}
