import type { MemeStatisticModel } from '@/models/MemeStatistic/Model'
import MemeStatisticsApi from '@/models/MemeStatistic/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeStatisticDetailsState extends DetailsState<MemeStatisticsApi, MemeStatisticModel> {
	api = new MemeStatisticsApi()
}

export function useMemeStatisticDetailsState() {
	return new MemeStatisticDetailsState()
}

export class MemeStatisticListState extends ListState<MemeStatisticsApi, MemeStatisticModel, LaravelPaginationResponse<MemeStatisticModel>> {
	api = new MemeStatisticsApi()
}

export function useMemeStatisticListState() {
	return new MemeStatisticListState()
}
