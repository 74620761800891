import type { MemeSubscriptionModel } from '@/models/MemeSubscription/Model'
import MemeSubscriptionsApi from '@/models/MemeSubscription/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeSubscriptionDetailsState extends DetailsState<MemeSubscriptionsApi, MemeSubscriptionModel> {
	api = new MemeSubscriptionsApi()
}

export function useMemeSubscriptionDetailsState() {
	return new MemeSubscriptionDetailsState()
}

export class MemeSubscriptionListState extends ListState<MemeSubscriptionsApi, MemeSubscriptionModel, LaravelPaginationResponse<MemeSubscriptionModel>> {
	api = new MemeSubscriptionsApi()
}

export function useMemeSubscriptionListState() {
	return new MemeSubscriptionListState()
}
