import type {
	MemeTemplateModel,
	MemeTemplateStorePayload,
	MemeTemplateUpdatePayload,
} from '@/models/MemeTemplate/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeTemplatesApi extends Api<
	MemeTemplateModel,
	LaravelPaginationResponse<MemeTemplateModel>,
	MemeTemplateStorePayload,
	MemeTemplateUpdatePayload
> {
	route = 'meme-templates'
}
