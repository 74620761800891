import type { AuthorModel } from '@/models/Author/Model'
import AuthorsApi from '@/models/Author/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class AuthorDetailsState extends DetailsState<AuthorsApi, AuthorModel> {
	api = new AuthorsApi()
}

export function useAuthorDetailsState() {
	return new AuthorDetailsState()
}

export class AuthorListState extends ListState<AuthorsApi, AuthorModel, LaravelPaginationResponse<AuthorModel>> {
	api = new AuthorsApi()
}

export function useAuthorListState() {
	return new AuthorListState()
}
