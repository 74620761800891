import type { FavoriteModel } from '@/models/Favorite/Model'
import FavoritesApi from '@/models/Favorite/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class FavoriteDetailsState extends DetailsState<FavoritesApi, FavoriteModel> {
	api = new FavoritesApi()
}

export function useFavoriteDetailsState() {
	return new FavoriteDetailsState()
}

export class FavoriteListState extends ListState<FavoritesApi, FavoriteModel, LaravelPaginationResponse<FavoriteModel>> {
	api = new FavoritesApi()
}

export function useFavoriteListState() {
	return new FavoriteListState()
}
