import type {
	MemeDiscussionModel,
	MemeDiscussionStorePayload,
	MemeDiscussionUpdatePayload,
} from '@/models/MemeDiscussion/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeDiscussionsApi extends Api<
	MemeDiscussionModel,
	LaravelPaginationResponse<MemeDiscussionModel>,
	MemeDiscussionStorePayload,
	MemeDiscussionUpdatePayload
> {
	route = 'meme-discussions'
}
