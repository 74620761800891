import type { MemeTrendModel } from '@/models/MemeTrend/Model'
import MemeTrendsApi from '@/models/MemeTrend/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeTrendDetailsState extends DetailsState<MemeTrendsApi, MemeTrendModel> {
	api = new MemeTrendsApi()
}

export function useMemeTrendDetailsState() {
	return new MemeTrendDetailsState()
}

export class MemeTrendListState extends ListState<MemeTrendsApi, MemeTrendModel, LaravelPaginationResponse<MemeTrendModel>> {
	api = new MemeTrendsApi()
}

export function useMemeTrendListState() {
	return new MemeTrendListState()
}
