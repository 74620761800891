import type { ReactionModel } from '@/models/Reaction/Model'
import ReactionsApi from '@/models/Reaction/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class ReactionDetailsState extends DetailsState<ReactionsApi, ReactionModel> {
	api = new ReactionsApi()
}

export function useReactionDetailsState() {
	return new ReactionDetailsState()
}

export class ReactionListState extends ListState<ReactionsApi, ReactionModel, LaravelPaginationResponse<ReactionModel>> {
	api = new ReactionsApi()
}

export function useReactionListState() {
	return new ReactionListState()
}
