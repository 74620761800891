import type {
	MemeEventModel,
	MemeEventStorePayload,
	MemeEventUpdatePayload,
} from '@/models/MemeEvent/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeEventsApi extends Api<
	MemeEventModel,
	LaravelPaginationResponse<MemeEventModel>,
	MemeEventStorePayload,
	MemeEventUpdatePayload
> {
	route = 'meme-events'
}
