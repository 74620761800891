<template>
	<FormContainer
		class="form"
		:visible
		:title="props.isEdit ? 'Update Order' : 'Create Order'"
		:as-dialog
		@close="emit('close')">
		<form @submit.prevent="submit">
				<FormInput
					v-if="!props.hideInputs?.includes('order_number')"
					:required="true"
					:error-message="formErrors.order_number"
					label="Order Number"
				>
					<InputText
						v-model="formData.order_number"
						:disabled="!!props.forceValues.order_number" />
				</FormInput>
				<FormInput
					v-if="!props.hideInputs?.includes('order_date')"
					:required="true"
					:error-message="formErrors.order_date"
					label="Order Date"
				>
					<DatePicker
						v-model="formData.order_date"
						:disabled="!!props.forceValues.order_date" />
				</FormInput>
				<FormInput
					v-if="!props.hideInputs?.includes('total_amount')"
					:required="true"
					:error-message="formErrors.total_amount"
					label="Total Amount"
				>
					<InputNumber
						v-model="formData.total_amount"
						:disabled="!!props.forceValues.total_amount"
						:max-fraction-digits="2"
						:max="10000000000" />
				</FormInput>
				<FormInput
					v-if="!props.hideInputs?.includes('payment_method')"
					:required="false"
					:error-message="formErrors.payment_method"
					label="Payment Method"
				>
					<Select
						v-model="formData.payment_method"
						:options="[{'title':'Cash','value':'Cash'},{'title':'Credit Card','value':'Credit Card'},{'title':'Debit Card','value':'Debit Card'},{'title':'Online','value':'Online'}]"
						:show-clear="true"
						:disabled="!!props.forceValues.payment_method"
						option-label="title"
						option-value="value" />
				</FormInput>
				<FormInput
					v-if="!props.hideInputs?.includes('payment_status')"
					:required="false"
					:error-message="formErrors.payment_status"
					label="Payment Status"
				>
					<Select
						v-model="formData.payment_status"
						:options="[{'title':'Paid','value':'Paid'},{'title':'Unpaid','value':'Unpaid'},{'title':'Refunded','value':'Refunded'},{'title':'Partial','value':'Partial'}]"
						:show-clear="true"
						:disabled="!!props.forceValues.payment_status"
						option-label="title"
						option-value="value" />
				</FormInput>
				<FormInput
					v-if="!props.hideInputs?.includes('customer_id')"
					:required="false"
					:error-message="formErrors.customer_id"
					label="Customer"
				>
					<ModelSelect
						v-model="formData.customer_id"
						:api="new CustomersApi()"
						:disabled="!!props.forceValues.customer_id"
						option-label="first_name" />
				</FormInput>
				<FormInput
					v-if="!props.hideInputs?.includes('store_id')"
					:required="false"
					:error-message="formErrors.store_id"
					label="Store"
				>
					<ModelSelect
						v-model="formData.store_id"
						:api="new StoresApi()"
						:disabled="!!props.forceValues.store_id"
						option-label="name" />
				</FormInput>
			<div class="form__footer-container">
				<Button
					v-if="props.isEdit && !props.hideRemove"
					severity="danger"
					icon="fal fa-trash"
					label="Remove"
					outlined
					:loading="loading"
					@click="remove" />
				<Button
					icon="fal fa-save"
					:loading="loading"
					:label="props.isEdit ? 'Update' : 'Create'"
					@click="submit"
					@submit.stop />
			</div>
		</form>
	</FormContainer>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import OrdersApi from '@/models/Order/Api'
import type { Order } from '@/models/Order/Model'
import { useForm } from '@/helpers/form'
import FormInput from '@/components/FormInput.vue'
import Button from 'primevue/button'
import FormContainer from '@/components/FormContainer.vue'
import CustomersApi from '@/models/Customer/Api'
import DatePicker from 'primevue/datepicker'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import ModelSelect from '@/components/ModelSelect.vue'
import Select from 'primevue/select'
import StoresApi from '@/models/Store/Api'

type FormData = {
	order_number: string,
	order_date: string,
	total_amount: number,
	payment_method: string,
	payment_status: string,
	customer_id: number | undefined,
	store_id: number | undefined,
}

const emit = defineEmits([
	'start-loading',
	'stop-loading',
	'close',
	'created',
	'updated',
	'deleted',
])

const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: Order['id']
		hideInputs?: (keyof FormData)[]
		defaultValues?: Partial<FormData>
		forceValues?: Partial<FormData>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
		hideRemove?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
		hideRemove: false,
	},
)

const router = useRouter()
const { formData, loading, formErrors, reset, submit, remove, isDirty } = useForm({
	api: new OrdersApi(),
	defaultData: () => ({
		order_number: '',
		order_date: '',
		total_amount: 0,
		payment_method: '',
		payment_status: '',
		customer_id: undefined,
		store_id: undefined,
	}),
	forceValues: props.forceValues,
	attachTo: props.attachTo,
	isEdit: props.isEdit,
	id: props.id,
	onStartLoading: () => emit('start-loading'),
	onStopLoading: () => emit('stop-loading'),
	onClose: () => emit('close'),
	onCreated: (entity) => {
		if (props.shouldRedirect) {
			router.push({ name: 'orders-edit', params: { id: entity!.id } })
		}
		emit('created', entity)
	},
	onUpdated: () => emit('updated'),
	onDeleted: () => emit('deleted'),
})

watch(
	() => props.visible,
	(val) => {
		if (!val) reset()
	},
)
</script>

<style lang="scss">
.form {
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__footer-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
		}

		&--edit {
			.form__footer-container {
				justify-content: space-between;
			}
		}
	}
}
</style>
