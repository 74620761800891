import type {
	FavoriteModel,
	FavoriteStorePayload,
	FavoriteUpdatePayload,
} from '@/models/Favorite/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class FavoritesApi extends Api<
	FavoriteModel,
	LaravelPaginationResponse<FavoriteModel>,
	FavoriteStorePayload,
	FavoriteUpdatePayload
> {
	route = 'favorites'
}
