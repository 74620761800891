import { createRouter, createWebHistory } from 'vue-router'
import authGuard from './guards/authGuard'
import { useAuthStore } from '@/stores/Auth'
import NotFound from '../views/NotFound.vue'

import Login from '../views/Auth/Login.vue'
import AdminApi from '@/helpers/api/AdminApi'
import BookList from '../views/Book/List.vue'
import BookEdit from '../views/Book/Edit.vue'
import AuthorList from '../views/Author/List.vue'
import AuthorEdit from '../views/Author/Edit.vue'
import CategoryList from '../views/Category/List.vue'
import CategoryEdit from '../views/Category/Edit.vue'
import PublisherList from '../views/Publisher/List.vue'
import PublisherEdit from '../views/Publisher/Edit.vue'
import CustomerList from '../views/Customer/List.vue'
import CustomerEdit from '../views/Customer/Edit.vue'
import EmployeeList from '../views/Employee/List.vue'
import EmployeeEdit from '../views/Employee/Edit.vue'
import StoreList from '../views/Store/List.vue'
import StoreEdit from '../views/Store/Edit.vue'
import OrderList from '../views/Order/List.vue'
import OrderEdit from '../views/Order/Edit.vue'
import UserList from '../views/User/List.vue'
import UserEdit from '../views/User/Edit.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			redirect: '/books',
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			beforeEnter: () => {
				const auth = useAuthStore()
				if (auth.user) {
					return { path: auth.lastRoute ?? '/posts' }
				}
			},
		},
		{
			path: '/logout',
			component: Login,
			beforeEnter: async () => {
				const auth = useAuthStore()
				await auth.logout()
				return { path: '/login' }
			},
		},
		{
			path: '/clear-data',
			component: Login,
			beforeEnter: async () => {
				await AdminApi.clearData()
				window.location.reload()
				throw new Error('Clear data')
			},
		},
		{
			path: '/books',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'books-list',
					component: BookList,
				},
				{
					path: '/books/create',
					name: 'books-create',
					component: BookEdit,
				},
				{
					path: '/books/:id',
					name: 'books-edit',
					component: BookEdit,
				},
			],
		},
		{
			path: '/authors',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'authors-list',
					component: AuthorList,
				},
				{
					path: '/authors/create',
					name: 'authors-create',
					component: AuthorEdit,
				},
				{
					path: '/authors/:id',
					name: 'authors-edit',
					component: AuthorEdit,
				},
			],
		},
		{
			path: '/categories',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'categories-list',
					component: CategoryList,
				},
				{
					path: '/categories/create',
					name: 'categories-create',
					component: CategoryEdit,
				},
				{
					path: '/categories/:id',
					name: 'categories-edit',
					component: CategoryEdit,
				},
			],
		},
		{
			path: '/publishers',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'publishers-list',
					component: PublisherList,
				},
				{
					path: '/publishers/create',
					name: 'publishers-create',
					component: PublisherEdit,
				},
				{
					path: '/publishers/:id',
					name: 'publishers-edit',
					component: PublisherEdit,
				},
			],
		},
		{
			path: '/customers',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'customers-list',
					component: CustomerList,
				},
				{
					path: '/customers/create',
					name: 'customers-create',
					component: CustomerEdit,
				},
				{
					path: '/customers/:id',
					name: 'customers-edit',
					component: CustomerEdit,
				},
			],
		},
		{
			path: '/employees',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'employees-list',
					component: EmployeeList,
				},
				{
					path: '/employees/create',
					name: 'employees-create',
					component: EmployeeEdit,
				},
				{
					path: '/employees/:id',
					name: 'employees-edit',
					component: EmployeeEdit,
				},
			],
		},
		{
			path: '/stores',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'stores-list',
					component: StoreList,
				},
				{
					path: '/stores/create',
					name: 'stores-create',
					component: StoreEdit,
				},
				{
					path: '/stores/:id',
					name: 'stores-edit',
					component: StoreEdit,
				},
			],
		},
		{
			path: '/orders',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'orders-list',
					component: OrderList,
				},
				{
					path: '/orders/create',
					name: 'orders-create',
					component: OrderEdit,
				},
				{
					path: '/orders/:id',
					name: 'orders-edit',
					component: OrderEdit,
				},
			],
		},
		{
			path: '/users',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'users-list',
					component: UserList,
				},
				{
					path: '/users/create',
					name: 'users-create',
					component: UserEdit,
				},
				{
					path: '/users/:id',
					name: 'users-edit',
					component: UserEdit,
				},
			],
		},
		{
			path: '/404-not-found',
			name: 'not-found',
			component: NotFound,
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/login',
		},
	],
})

export default router
