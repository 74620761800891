import type { StoreModel } from '@/models/Store/Model'
import StoresApi from '@/models/Store/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class StoreDetailsState extends DetailsState<StoresApi, StoreModel> {
	api = new StoresApi()
}

export function useStoreDetailsState() {
	return new StoreDetailsState()
}

export class StoreListState extends ListState<StoresApi, StoreModel, LaravelPaginationResponse<StoreModel>> {
	api = new StoresApi()
}

export function useStoreListState() {
	return new StoreListState()
}
