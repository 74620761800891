import type { MemeCollectionModel } from '@/models/MemeCollection/Model'
import MemeCollectionsApi from '@/models/MemeCollection/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeCollectionDetailsState extends DetailsState<MemeCollectionsApi, MemeCollectionModel> {
	api = new MemeCollectionsApi()
}

export function useMemeCollectionDetailsState() {
	return new MemeCollectionDetailsState()
}

export class MemeCollectionListState extends ListState<MemeCollectionsApi, MemeCollectionModel, LaravelPaginationResponse<MemeCollectionModel>> {
	api = new MemeCollectionsApi()
}

export function useMemeCollectionListState() {
	return new MemeCollectionListState()
}
