import type {
	PublisherModel,
	PublisherStorePayload,
	PublisherUpdatePayload,
} from '@/models/Publisher/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class PublishersApi extends Api<
	PublisherModel,
	LaravelPaginationResponse<PublisherModel>,
	PublisherStorePayload,
	PublisherUpdatePayload
> {
	route = 'publishers'
}
