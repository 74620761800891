import type { MemeEventModel } from '@/models/MemeEvent/Model'
import MemeEventsApi from '@/models/MemeEvent/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class MemeEventDetailsState extends DetailsState<MemeEventsApi, MemeEventModel> {
	api = new MemeEventsApi()
}

export function useMemeEventDetailsState() {
	return new MemeEventDetailsState()
}

export class MemeEventListState extends ListState<MemeEventsApi, MemeEventModel, LaravelPaginationResponse<MemeEventModel>> {
	api = new MemeEventsApi()
}

export function useMemeEventListState() {
	return new MemeEventListState()
}
