import type { ReportModel } from '@/models/Report/Model'
import ReportsApi from '@/models/Report/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class ReportDetailsState extends DetailsState<ReportsApi, ReportModel> {
	api = new ReportsApi()
}

export function useReportDetailsState() {
	return new ReportDetailsState()
}

export class ReportListState extends ListState<ReportsApi, ReportModel, LaravelPaginationResponse<ReportModel>> {
	api = new ReportsApi()
}

export function useReportListState() {
	return new ReportListState()
}
