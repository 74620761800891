import type { BookModel } from '@/models/Book/Model'
import BooksApi from '@/models/Book/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class BookDetailsState extends DetailsState<BooksApi, BookModel> {
	api = new BooksApi()
}

export function useBookDetailsState() {
	return new BookDetailsState()
}

export class BookListState extends ListState<BooksApi, BookModel, LaravelPaginationResponse<BookModel>> {
	api = new BooksApi()
}

export function useBookListState() {
	return new BookListState()
}
