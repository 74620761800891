import type {
	MemeStatisticModel,
	MemeStatisticStorePayload,
	MemeStatisticUpdatePayload,
} from '@/models/MemeStatistic/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeStatisticsApi extends Api<
	MemeStatisticModel,
	LaravelPaginationResponse<MemeStatisticModel>,
	MemeStatisticStorePayload,
	MemeStatisticUpdatePayload
> {
	route = 'meme-statistics'
}
