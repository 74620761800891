import type {
	MemeFormatModel,
	MemeFormatStorePayload,
	MemeFormatUpdatePayload,
} from '@/models/MemeFormat/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeFormatsApi extends Api<
	MemeFormatModel,
	LaravelPaginationResponse<MemeFormatModel>,
	MemeFormatStorePayload,
	MemeFormatUpdatePayload
> {
	route = 'meme-formats'
}
