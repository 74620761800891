import type {
	MemeFeedbackModel,
	MemeFeedbackStorePayload,
	MemeFeedbackUpdatePayload,
} from '@/models/MemeFeedback/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class MemeFeedbacksApi extends Api<
	MemeFeedbackModel,
	LaravelPaginationResponse<MemeFeedbackModel>,
	MemeFeedbackStorePayload,
	MemeFeedbackUpdatePayload
> {
	route = 'meme-feedbacks'
}
