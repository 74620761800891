import type { BookModel, BookStorePayload, BookUpdatePayload } from '@/models/Book/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class BooksApi extends Api<
	BookModel,
	LaravelPaginationResponse<BookModel>,
	BookStorePayload,
	BookUpdatePayload
> {
	route = 'books'
}
