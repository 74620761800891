<template>
	<Header :title="isEdit ? 'Edit Meme' : 'Create Meme'" />
	<ProgressBar
		v-if="loaders.size > 0"
		mode="indeterminate"
		class="edit__progress-bar" />
	<Container class="edit">
		<Form
			:id="route.params.id as string"
			:is-edit="isEdit"
			@start-loading="loaders.add('form')"
			@stop-loading="loaders.delete('form')"
			@deleted="router.push({ name: 'memes-list' })" />
<TagsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Tags')"
	@stop-loading="loaders.delete('Tags')" />
<CategoriesRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Categories')"
	@stop-loading="loaders.delete('Categories')" />
<CommentsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Comments')"
	@stop-loading="loaders.delete('Comments')" />
<ReactionsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Reactions')"
	@stop-loading="loaders.delete('Reactions')" />
<FavoritesRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Favorites')"
	@stop-loading="loaders.delete('Favorites')" />
<ReportsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Reports')"
	@stop-loading="loaders.delete('Reports')" />
<EventsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Events')"
	@stop-loading="loaders.delete('Events')" />
<ContestsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Contests')"
	@stop-loading="loaders.delete('Contests')" />
<AwardsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Awards')"
	@stop-loading="loaders.delete('Awards')" />
<CollectionsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Collections')"
	@stop-loading="loaders.delete('Collections')" />
<ChallengesRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Challenges')"
	@stop-loading="loaders.delete('Challenges')" />
<TrendsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Trends')"
	@stop-loading="loaders.delete('Trends')" />
<HistoryRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('History')"
	@stop-loading="loaders.delete('History')" />
<LicensesRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Licenses')"
	@stop-loading="loaders.delete('Licenses')" />
<StatisticsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Statistics')"
	@stop-loading="loaders.delete('Statistics')" />
<FeedbackRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Feedback')"
	@stop-loading="loaders.delete('Feedback')" />
<SubscriptionsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Subscriptions')"
	@stop-loading="loaders.delete('Subscriptions')" />
<PollsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Polls')"
	@stop-loading="loaders.delete('Polls')" />
<DiscussionsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Discussions')"
	@stop-loading="loaders.delete('Discussions')" />
<InsightsRelationWidget
	v-if="isEdit"
	:meme-id="route.params.id as string"
	@start-loading="loaders.add('Insights')"
	@stop-loading="loaders.delete('Insights')" />
	</Container>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Header from './components/Header.vue'
import Form from './components/Form.vue'
import ProgressBar from 'primevue/progressbar'
import Container from '@/components/Container.vue'
import TagsRelationWidget from './components/TagsRelationWidget.vue'
import CategoriesRelationWidget from './components/CategoriesRelationWidget.vue'
import CommentsRelationWidget from './components/CommentsRelationWidget.vue'
import ReactionsRelationWidget from './components/ReactionsRelationWidget.vue'
import FavoritesRelationWidget from './components/FavoritesRelationWidget.vue'
import ReportsRelationWidget from './components/ReportsRelationWidget.vue'
import EventsRelationWidget from './components/EventsRelationWidget.vue'
import ContestsRelationWidget from './components/ContestsRelationWidget.vue'
import AwardsRelationWidget from './components/AwardsRelationWidget.vue'
import CollectionsRelationWidget from './components/CollectionsRelationWidget.vue'
import ChallengesRelationWidget from './components/ChallengesRelationWidget.vue'
import TrendsRelationWidget from './components/TrendsRelationWidget.vue'
import HistoryRelationWidget from './components/HistoryRelationWidget.vue'
import LicensesRelationWidget from './components/LicensesRelationWidget.vue'
import StatisticsRelationWidget from './components/StatisticsRelationWidget.vue'
import FeedbackRelationWidget from './components/FeedbackRelationWidget.vue'
import SubscriptionsRelationWidget from './components/SubscriptionsRelationWidget.vue'
import PollsRelationWidget from './components/PollsRelationWidget.vue'
import DiscussionsRelationWidget from './components/DiscussionsRelationWidget.vue'
import InsightsRelationWidget from './components/InsightsRelationWidget.vue'

const route = useRoute()
const router = useRouter()
const isEdit = computed(() => route.name === 'memes-edit')
const loaders = reactive(new Set<string>())
</script>

<style lang="scss" scoped>
.edit__progress-bar {
	height: 4px;
	margin-bottom: -4px;
	width: 100%;
	border-radius: 0;
}

.edit {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	gap: 20px;
	justify-items: center;
	align-items: start;
}
</style>
